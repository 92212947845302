<template>
  <div class="width100 background-color26">
  <!--<div>
      <el-carousel height="350px" :interval="3000">
        <el-carousel-item>
          <el-image
            class="width100 height100"
            :src="banner1"
            fit="cover"
          ></el-image>
        </el-carousel-item>
        <el-carousel-item>
          <el-image
            class="width100 height100"
            :src="banner2"
            fit="cover"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>-->
    <div class="flex flex-center padding-top3 padding-bottom3">
      <img class="title-img" src="@/assets/home/title.png" alt="title">
      <div class="fontSize18 font-weight textColor03 margin-left3">推荐商家</div>
      <div class="fontSize14 textColor09 margin-left3 flex1">每天为你推荐就近商家，离得近服务才更到位</div>
      <div class="background-color27 border-radius4 cursor-pointer actives05 textColor10 fontSize14 padding-left3 padding-right3 line-height2"
           @click="$router.push('/home/search?queryType=2')">
        更多
      </div>
    </div>
    <div class="min-height500 box-sizing grid-container el-loading-spinners" v-loading="loading">
      <el-row>
        <el-col :span="12" v-for="item in recommendList" :key="item.id" class="box-sizing info-item-box">
          <div class="info-item width100 box-sizing padding2 border-radius1 flex flex-center actives05 cursor-pointer transition03 background-color10">
            <div class="height100 flex flex-direction-column" @click="jump(item.id)">
              <div class="line-height0">
                <el-image class="info-item-img border-radius1 overflow-hidden" fit="cover" :src="(item.images.length ? item.images[0] : item.logoImage) || defaultImg " alt=""/>
              </div>
            </div>
            <div class="height100 flex1 margin-left3" @click="jump(item.id)">
              <div class="fontSize16 textColor03 font-weight text-show-one">
                {{ item.name }}
              </div>
              <div class="fontSize14 textColor03 font-weight400 margin-top1 text-show-one">
                {{ renderCategories(item) }}
              </div>
              <div class="fontSize13 font-weight400 margin-top1">
                <span v-if="item.type" class="padding-left1 padding-right1 border-radius1 textColor24 border4 background-color29 margin-right2">
                  {{ renderType(item) }}
                </span>
                <span v-if="item.flagThreesoft" class="padding-left1 padding-right1 border-radius1 textColor23 border3 margin-right2">
                思锐认证
              </span>
                <span v-if="item.flagOptimization" class="padding-left1 padding-right1 border-radius1 textColor23 border3 margin-right2">
                优质商家
              </span>
                <span v-if="item.flagPower" class="padding-left1 padding-right1 border-radius1 textColor23 border3">
                实力商家
              </span>
              </div>
              <div class="fontSize12 textColor06 font-weight400 margin-top1">
                {{ item.areas ? item.areas[1] : "" }}
              </div>
              <div v-if="item.description" class="fontSize12 textColor06 font-weight400 margin-top1 line-height2 text-show-one">
                <span v-if="item.flagOptimization" class="fontSize14 textColor10 background-color27 padding1 border-radius1 margin-right2">荐</span>
                <span>{{ item.description }}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {getLocation} from "@/utils/location";
import {getRecommendList} from "@/api/home";
import eventBus from "@/event";

export default {
  data() {
    return {
      defaultImg: require("@/assets/maintenance/default-store.png"),
      // banner1: require("@/assets/home/banner1.png"),
      // banner2: require("@/assets/home/banner2.png"),
      recommendList: [],
      loading: true
    };
  },
  created() {
    getLocation("homePage");
    // 控制首页定位时请求数据
    eventBus.$on("getHomePageData", state => {
      if (state) {
        eventBus.$off("getHomePageData");
        this.getRecommendList();
      }
    });
  },
  methods: {
    jump(id) {
      const obj = {
        path: "/home/store",
        query: {
          storeId: id
        },
      };
      const routeData = this.$router.resolve(obj);
      window.open(routeData.href, '_blank');
    },
    getRecommendList() {
      const locationStr = localStorage.getItem("location");
      let localProvince = "";
      let localCity = "";
      if (locationStr) {
        const {province, city} = JSON.parse(locationStr);
        localProvince = province;
        localCity = city;
      }
      this.loading = true;
      getRecommendList({
        province: localProvince,
        city: localCity,
      }).then((res) => {
        for (let item of res) {
          if (!item.images) {
            item.images = [];
          }
        }
        this.recommendList = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    renderType(data) {
      let str = "";
      switch (data.type) {
        case "dealer":
          str = "经销商";
          this.typeClass = "flag_j";
          break;
        case "garage":
          str = "汽修厂";
          this.typeClass = "flag_q";
          break;
        case "manufacturer":
          str = "生产厂家";
          this.typeClass = "flag_s";
          break;
      }
      return str;
    },
    renderCategories(data) {
      let arr = [];
      const categories = data.categories;
      if (categories && categories.length) {
        categories.forEach((p) => {
          arr.push(`${p[0]}>${p[1]}`);
        });
      }
      return arr.join(" | ");
    },
  },
};
</script>
<style scoped>
.title-img {
  width: 24px;
  height: 24px;
}

.info-item-box {
  margin-bottom: 16px;
}

.info-item-box:nth-child(odd) {
  padding-right: 8px;
}

.info-item-box:nth-child(even) {
  padding-left: 8px;
}

.info-item {
  height: 150px;
  border: 1px solid #ffffff;
}

.info-item:hover {
  box-shadow: 0 0 10px #e6e7ea;
  background-color: #ffffff;
  border: 1px solid #FF6E4C;
}

.info-item-img {
  width: 200px;
  height: 130px;
}
</style>