import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

export function getRecommendList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/recommend",
    method: "get",
    params
  });
}

export function addAddressApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/addresses",
    method: "post",
    data
  });
}

//查询未得列表
export function getSearchUnfoundApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/enquiry/search-unfound/list",
    method: "get",
    params
  });
}